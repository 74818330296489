$(function() {

  var navMenuEl = document.getElementById('#nav-dropdown');
  var bookATourFormEl = document.getElementById('#book-a-tour-form');

  $('.page-overlay').click(function(){
    if ($('body').hasClass('nav-open')) {
      $('body').removeClass('nav-open')
      bodyScrollLock.enableBodyScroll(navMenuEl);
    // } else if ($('body').hasClass('video-lightbox-show')) {
    //   $('body').removeClass('video-lightbox-show')
    //   bodyScrollLock.enableBodyScroll(videoLightboxEl);
    }
  });

  $('.nav-toggle').click(function(){
    if ($('body').hasClass('nav-open')) {
      $('body').removeClass('nav-open');
      bodyScrollLock.enableBodyScroll(navMenuEl);
    } else {
      // window.scrollTo(0, 0);
      if ($('body').hasClass('book-a-tour-open')) {
        $('body').removeClass('book-a-tour-open');
      }
      $('body').addClass('nav-open');
      bodyScrollLock.disableBodyScroll(navMenuEl);
    }
  });

  $('.nav-search-toggle').click(function(){
    if ($('body').hasClass('nav-open')) {
      $('body').removeClass('nav-open');
      bodyScrollLock.enableBodyScroll(navMenuEl);
    } else {
      // window.scrollTo(0, 0);
      if ($('body').hasClass('book-a-tour-open')) {
        $('body').removeClass('book-a-tour-open');
      }
      $('body').addClass('nav-open');
      $('#search').focus();
      bodyScrollLock.disableBodyScroll(navMenuEl);
    }
  });

  $('.book-a-tour,#book-a-tour-form-close').click(function(e){
    e.preventDefault();
    if ($('body').hasClass('book-a-tour-open')) {
      $('body').removeClass('book-a-tour-open');
      bodyScrollLock.enableBodyScroll(bookATourFormEl);
    } else {
      if ($('body').hasClass('nav-open')) {
        $('body').removeClass('nav-open');
      }
      $('body').addClass('book-a-tour-open');
      bodyScrollLock.disableBodyScroll(bookATourFormEl);
    }
  });

  $('.partners-slider').slick({
    slidesToShow: 6,
    slidesToScroll: 1,
    swipeToSlide: true,
    variableWidth: true,
    appendArrows: $('.partners-slider-wrapper'),
    prevArrow: '<span class="icon-angle-left"></span>',
    nextArrow: '<span class="icon-angle-right"></span>',
    responsive: [
      // {
      //   breakpoint: 1200, // laptop
      //   settings: {
      //     slidesToShow: 4
      //   }
      // },
      // {
      //   breakpoint: 992, // xl tablet
      //   settings: {
      //     slidesToShow: 3
      //   }
      // },
      // {
      //   breakpoint: 768, // tablet
      //   settings: {
      //     slidesToShow: 2
      //   }
      // },
      {
        breakpoint: 767, // phones
        settings: {
          slidesToShow: 1,
          variableWidth: false
        }
      }
    ]
  });

  $('.quotes-slider').flickity({
    freeScroll: true,
    wrapAround: true,
    prevNextButtons: false,
    autoPlay: 5000,
    pauseAutoPlayOnHover: true
  });

  $('.values-slider').slick({
    dots: true,
    swipeToSlide: true,
    prevArrow: '<span class="icon-angle-left"></span>',
    nextArrow: '<span class="icon-angle-right"></span>'
  });

  $('.team-slider').slick({
    centerMode: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    prevArrow: '<span class="icon-angle-left"></span>',
    nextArrow: '<span class="icon-angle-right"></span>',
    responsive: [
      // {
      //   breakpoint: 1200, // laptop
      //   settings: {
      //     slidesToShow: 3
      //   }
      // },
      {
        breakpoint: 992, // xl tablet
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768, // tablet
        settings: {
          slidesToShow: 1
        }
      // },
      // {
      //   breakpoint: 767, // phones
      //   settings: {
      //     centerMode: false,
      //     slidesToShow: 1
      //   }
      }
    ]
  });

  $('#show-more').click(function(){

    var $this = $(this);
    var page = $this.data('page');

    $.get($this.data('url') + '/p' + page, function(data) {
      $('#listing').append(data);
      page = page + 1;
      if ($this.data('max') == page) {
        $this.parents('.show-more-wrapper').addClass('hide');
      } else {
        $this.data('page', page);
      }

    }).fail(function() {
      alert('error');
    });
  });

  $('.btn-plus').click(function(){
    $('.row-' + $(this).data('row')).addClass('show');
    $(this).hide();
  });

});
